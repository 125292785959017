<template>
  <div>
    <section class="main-banner" id="main">
      <div class="round-left"></div>
      <div class="round-right"></div>
      <div class="ts-container">
        <div class="dots"></div>
        <div class="main-banner-wrapper">
          <div class="adaptive-helper">
            <div class="main-banner-label">
              <h1 class="main-banner-label-bold">TROUBLESHOOTING</h1>
              <h1 class="main-banner-label-normal">{{ $t("Команда") }}</h1>
              <span class="main-banner-label-text">{{ $t("Описание-главная") }}</span>
            </div>
            <div class="button-arrow-wrapper">
              <div class="arrows"></div>
              <button @click="scrollTo('write')" class="write-to-us">{{ $t("Связаться") }}</button>
            </div>
          </div>
          <div class="workers-img"></div>
        </div>
      </div>
    </section>
    <section class="industries" id="industries">
      <div class="ts-container">
        <div class="section-title">{{ $t('ПриоритетныеОтрасли') }}</div>
        <div class="industries-wrapper">
          <div class="industries-roud">
          </div>
          <div class="industries-card">
            <div class="industries-business"></div>
            <div class="industries-label">{{ $t("Бизнес") }}</div>
          </div>
          <div class="industries-card">
            <div class="industries-gov"></div>
            <div class="industries-label">{{ $t("Госсектор") }}</div>
          </div>
          <div class="industries-card">
            <div class="industries-it"></div>
            <div class="industries-label">{{ $t("IT") }}</div>
          </div>
          <div class="industries-card">
            <div class="industries-education"></div>
            <div class="industries-label">{{ $t("Образование") }}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="approaches">
      <div class="ts-container">
        <div class="section-title">{{ $t('Подходы') }}</div>
        <div class="approaches-wrapper">
          <div class="approaches-card">
            <div class="approaches-card-half">
              <div class="graph"></div>
            </div>
            <div class="approaches-card-text">
              <div class="approaches-card-title">{{ $t('Понимаем') }}</div>
              <span>{{ $t('ДумаемИДелаем') }}</span>
            </div>
          </div>
          <div class="approaches-card">
            <div class="approaches-card-half">
              <div class="diploma"></div>
            </div>
            <div class="approaches-card-text">
              <div class="approaches-card-title">{{ $t('Гарантируем') }}</div>
              <span>{{ $t("ЮридическаяГарантия") }}</span>
            </div>
          </div>
          <div class="approaches-card">
            <div class="approaches-card-half">
              <div class="chat"></div>
            </div>
            <div class="approaches-card-text">
              <div class="approaches-card-title">{{ $t('Небросаем') }}</div>
              <span>{{ $t('Гарантия') }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="our-case" id="cases">
      <div class="ts-container">
        <div class="section-title">{{ $t('Нашикейсы') }}</div>
        <div class="nav-bar">
          <div @click="currentFilter='all'" :class="{'nav-bar-item-active' :currentFilter=='all'}" class="nav-bar-item">
            {{ $t('Все') }}
          </div>
          <div @click="currentFilter='site'" :class="{'nav-bar-item-active' :currentFilter=='site'}"
               class="nav-bar-item"> {{ $t('Сайты') }}
          </div>
          <div @click="currentFilter='mobile'" :class="{'nav-bar-item-active' :currentFilter=='mobile'}"
               class="nav-bar-item ">{{ $t('МобильныеПриложения') }}
          </div>
          <div @click="currentFilter='portal'" :class="{'nav-bar-item-active' :currentFilter=='portal'}"
               class="nav-bar-item">{{ $t('Порталы') }}
          </div>
        </div>
        <div class="project-wrapper-btn">
          <a href="/cases">{{ $t('ПосмотретьВсе') }}</a>
        </div>

        <div class="project-wrapper">
          <div :key="index" v-for="(item,index) in filteredCase"
               v-bind:class="{ 'project-item-big': arrBig.indexOf(index+1) != -1, 'project-item-min': arrMin.indexOf(index+1) != -1}">
            <div v-if="item.logoImage" :style="{backgroundImage: `url(${item.logoImage})`}"
                 class="project-item-logo"></div>
            <div v-bind:class="{ 'project-item-none-logo': item.logoImage=='' }"
                 :style="{backgroundImage: `url(${item.deviceImage})`}" class="project-item-device"></div>
          </div>
        </div>
      </div>
    </section>
    <section class="our-clients" id="clients">
      <div class="ts-container">
        <div class="section-title">{{ $t('Нашиклиенты') }}</div>
      </div>
      <carousel v-if="showCarousel" :items="items" :autoplay="true"
                :autoplayTimeout="3000"
                :autoplayHoverPause="true">

        <div class="clients-card">
          <div class="myn-bala"></div>
        </div>


        <div class="clients-card">
          <div class="umc-ok"></div>
        </div>


        <div class="clients-card">
          <div class="texme"></div>
        </div>


        <div class="clients-card">
          <div class="gastreat"></div>
        </div>


        <div class="clients-card">
          <div class="birge-oqy"></div>
        </div>

        <div class="clients-card">
          <div class="djoon-client"></div>
        </div>

        <div class="clients-card">
          <div class="qabilet"></div>
        </div>

      </carousel>
    </section>
    <section class="partners">
      <div class="ts-container">
        <div class="section-title">{{ $t('Партнеры') }}</div>
        <div class="partners-wrapper">
          <div class="partners-card">
            <div class="partners-card-logo">
              <div class="djoon"></div>
            </div>
            <div class="partners-card-text">{{ $t('Joon-описание') }}
            </div>
          </div>
          <div class="partners-card">
            <div class="partners-card-logo">
              <div class="digital-kazakhstan"></div>
            </div>
            <div class="partners-card-text">{{ $t('digitalKz-описание') }}
            </div>
          </div>
          <div class="partners-card">
            <div class="partners-card-logo">
              <div class="kaz-teleport"></div>
            </div>
            <div class="partners-card-text">{{ $t('kaz-teleport-описание') }}
            </div>
          </div>
          <div class="partners-card">
            <div class="partners-card-logo">
              <div class="sailet"></div>
            </div>
            <div class="partners-card-text">{{ $t('sailet-описание') }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="write" class="feedback-form-section">
      <div class="ts-container">
        <div class="section-title">{{ $t('Сотрудничество') }}</div>
        <div class="feedback-wrapper">
          <div class="feedback-form">
            <div class="feedback-logo"></div>
            <div class="feedback-input-wrapper">
              <input placeholder="Имя" class="ts-input" type="text">
              <input placeholder="E-Mail" class="ts-input" type="text">
            </div>
            <textarea placeholder="Сообщение" class="ts-text-area"></textarea>
            <button class="write-to-us feedback-form-btn">{{ $t('Связаться') }}</button>
          </div>
          <div class="feedback-logo-wrap">
            <div class="ts-logo-opacity"></div>
            <div class="feedback-text-opacity">TROUBLESHOOTING</div>
            <div class="triangle-dots"></div>
          </div>
        </div>
      </div>
    </section>
    <section id="contact" class="contact-block">
      <div class="ts-container">
        <div class="section-title">{{ $t('Контакты') }}</div>
        <div class="contact-wrapper">
          <div class="contact-item">
            <div class="email-ico"></div>
            <a class="contact-link" href="mailto:it@ts-technology.kz">it@ts-technology.kz</a>
          </div>
          <div class="contact-item">
            <div class="map-pin"></div>
            <a class="contact-link" target="_blank"
               href="https://goo.gl/maps/kEwfsm1Stfsm1iEC8">{{ $t('Местоположение') }}</a>
          </div>
          <div class="contact-item">
            <div class="phone-ico"></div>
            <a class="contact-link" href="tel:+77067080424">+7 706 708 0424</a>
          </div>
        </div>
        <div class="map-wrapper">
          <div class="contact-round"></div>
          <div class="requisites-block">
            <span class="requisites-block-title">{{ $t('Реквизиты') }}</span>
            <span class="requisites-block-element">ТОО "TROUBLESHOOTING TECHNOLOGY"</span>
            <span class="requisites-block-element">БИН 191140015704</span>
            <span class="requisites-block-element">ИИК KZ51998BTB0000517245 </span>
            <span class="requisites-block-element">АО «First Heartland Jýsan Bank»</span>
            <span class="requisites-block-element">БИК TSESKZKA</span>
          </div>
          <div class="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2906.5964320532607!2d76.90289601496097!3d43.23892028713921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3883693aeb7dfe15%3A0x19e7c91d09661b35!2z0L_RgC3Rgi4g0JDQsdCw0Y8gNjgvNzQsINCQ0LvQvNCw0YLRiyA1MDA1MDA!5e0!3m2!1sru!2skz!4v1643264170689!5m2!1sru!2skz"
                style="border:0; border-radius: 15px; width: 100%;height: 100%" allowfullscreen=""
                loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>

</template>
<script>
import carousel from 'vue-owl-carousel'

export default {
  components: {carousel},
  name: 'Header',
  data() {
    return {
      showCarousel: false,
      items: 4,
      width: 0,
      currentFilter: 'all',
      arrBig: [],
      arrMin: [],
      filteredCase: [],
      cases: [
        {
          name: 'Gastreat',
          logoImage: './images/case/mobile/logos/gastreat.png',
          deviceImage: './images/case/mobile/device/gastreat.png',
          deviceType: 'mobile',
        },
        {
          name: 'Qabilet',
          logoImage: './images/case/mobile/logos/qabilet.png',
          deviceImage: './images/case/mobile/device/qabilet.png',
          deviceType: 'mobile',
        },
        {
          name: 'BirgeOqu',
          logoImage: './images/case/mobile/logos/BirgeOqy.png',
          deviceImage: './images/case/mobile/device/BirgeOqy.png',
          deviceType: 'mobile',
        },
        {
          name: 'Wiedgergebut',
          logoImage: './images/case/mobile/logos/wiedergeburt.png',
          deviceImage: './images/case/mobile/device/wiedgerburt.png',
          deviceType: 'mobile',
        },
        {
          name: 'umc_ok',
          logoImage: './images/case/mobile/logos/umc_ok.png',
          deviceImage: './images/case/mobile/device/umc_ok.png',
          deviceType: 'mobile',
        },
        {
          name: '1000bala',
          logoImage: './images/case/portal/logos/1000bala-logo.png',
          deviceImage: './images/case/portal/device/1000bala.png',
          deviceType: 'portal',
        },
        {
          name: 'BirgeOqu',
          logoImage: './images/case/mobile/logos/BirgeOqy.png',
          deviceImage: './images/case/portal/device/BirgeOqy.png',
          deviceType: 'portal',
        },
        {
          name: 'cg-service',
          logoImage: './images/case/portal/logos/cg.png',
          deviceImage: './images/case/portal/device/cg-service.png',
          deviceType: 'portal',
        },
        {
          name: 'dashboard',
          logoImage: '',
          deviceImage: './images/case/portal/device/dashboard.png',
          deviceType: 'portal',
        },
        {
          name: 'ibc',
          logoImage: '',
          deviceImage: './images/case/portal/device/ibc.png',
          deviceType: 'portal',
        },
        {
          name: 'kamcor',
          logoImage: '',
          deviceImage: './images/case/portal/device/kamcor.png',
          deviceType: 'portal',
        },
        {
          name: 'library',
          logoImage: '',
          deviceImage: './images/case/portal/device/library.png',
          deviceType: 'portal',
        },
        {
          name: 'Qabilet',
          logoImage: './images/case/mobile/logos/qabilet.png',
          deviceImage: './images/case/portal/device/Qabilet.png',
          deviceType: 'portal',
        },
        {
          name: 'reestrpPo',
          logoImage: '',
          deviceImage: './images/case/portal/device/reestrPo.png',
          deviceType: 'portal',
        },
        {
          name: 'Texme',
          logoImage: '/images/clients/texme.svg',
          deviceImage: './images/case/portal/device/Texme.png',
          deviceType: 'portal',
        },
        {
          name: 'Trains',
          logoImage: './images/case/portal/logos/trains.png',
          deviceImage: './images/case/portal/device/trains.png',
          deviceType: 'portal',
        },
        {
          name: 'tumbler',
          logoImage: '',
          deviceImage: './images/case/portal/device/tumbler.png',
          deviceType: 'portal',
        },


        {
          name: 'agmp',
          logoImage: './images/case/site/logos/agmp_news.png',
          deviceImage: './images/case/site/device/agmp.png',
          deviceType: 'site',
        },
        {
          name: 'CentralComunication',
          logoImage: '',
          deviceImage: './images/case/site/device/centralComunication.png',
          deviceType: 'site',

        },
        {
          name: 'elbasy-lending.png',
          logoImage: './images/case/site/logos/EA_logo_final.png',
          deviceImage: './images/case/site/device/elbasy-lending.png',
          deviceType: 'site',

        },
        {
          name: 'ibc',
          logoImage: './images/case/site/logos/ibc.png',
          deviceImage: './images/case/site/device/ibc.png',
          deviceType: 'site',

        },
        {
          name: 'Qabilet',
          logoImage: './images/case/mobile/logos/qabilet.png',
          deviceImage: './images/case/site/device/qabilet.png',
          deviceType: 'site',

        },
        {
          name: 'qazgeology',
          logoImage: './images/case/site/logos/qazgeology.png',
          deviceImage: './images/case/site/device/qazgeology.png',
          deviceType: 'site',

        },
        {
          name: 'reghub',
          logoImage: './images/case/site/logos/reghub.png',
          deviceImage: './images/case/site/device/reghub.png',
          deviceType: 'site',

        },

      ]
    }
  },

  watch: {
    'width'() {
      this.updateWidth()
    },
    'currentFilter'() {
      this.filterCase()
    }
  },
  created() {
    const scrollTo = this.scrollTo.bind(this)
    window.addEventListener("load", () => setTimeout(function () {
      scrollTo()
    }, 1));
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener("load", () => setTimeout(function () {
      scrollTo()
    }, 1));
    window.removeEventListener('resize', this.updateWidth);
  },
  mounted() {

    this.updateWidth()
    this.filterCase()
    var counter = 1
    for (var i = 0; i < this.cases.length; i++) {
      if (counter == 1) {
        this.arrBig.push(i + 1)
      }
      if (counter == 2) {
        this.arrMin.push(i + 1)
      }
      if (counter == 3) {
        this.arrMin.push(i + 1)
      }
      if (counter == 4) {
        this.arrBig.push(i + 1)
      }
      if (counter >= 4) {
        counter = 0
      }
      counter++
    }


  },
  methods: {
    shuffle(array) {
      let currentIndex = array.length, randomIndex;
      while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    updateWidth() {
      this.showCarousel = false
      this.width = window.innerWidth;

      if (this.width >= 600 && this.width < 800) {
        this.items = 2
      } else if (this.width >= 800 && this.width < 1100) {
        this.items = 3
      } else if (this.width >= 1100) {
        this.items = 4
      } else {
        this.items = 1
      }
      this.showCarousel = true
    },
    filterCase() {
      if (this.currentFilter == "all") {
        this.filteredCase = this.shuffle(this.cases).slice(-6)
      } else {
        this.filteredCase = this.shuffle(this.cases.filter(word => word.deviceType == this.currentFilter)).slice(-6);
      }
    },

    scrollTo(block) {
      if (typeof block != "string") {
        block = location.hash.slice(1)
      }
      if (this.$route.path == '/') {
        const yOffset = -200;
        const element = document.getElementById(block);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: y, behavior: 'smooth'});
        this.adaptiveMenu = false
      } else {
        this.$router.push({name: '/', hash: '#' + block})
      }
    },
  }
}
</script>
